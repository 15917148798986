<template>
	<br />
</template>

<script>
	import { fireDeleteOrderById, fireGet, fireAsyncSignIn } from '../db';

	export default {
		name: 'Cancel',
		props: {
			orderId: {
				type: String,
				default: ''
			},
			customDeletionId: {
				type: String,
				default: 'not-valid'
			}
		},
		mounted() {
			fireAsyncSignIn()
				.then(() => {
					this.mountedFunc();
				})
				.catch(e => {
					console.log(`fireAsyncSignIn Error ${e.message}`);
				});
		},
		methods: {
			mountedFunc() {
				fireGet(this.orderId).then(data => {
					const fireCustomDeletionId = JSON.parse(
						data?.raw
					).customDeletionId;

					let error = false;
					if (
						fireCustomDeletionId ===
						localStorage.getItem('customDeletionId')
					) {
						fireDeleteOrderById(this.orderId);
					} else {
						error = true;
					}

					if (error) {
						console.error('Error! Could not delete order');
					}
				});

				localStorage.setItem('customDeletionId', '');
				this.$router.push('/');
			}
		}
	};
</script>

<style scoped></style>
